import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout.js";
import Section from "../components/Section.js";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import ConditionalWrap from "../components/utilities/ConditionalWrap";
import TrackedLink from "../components/utilities/TrackedLink";
import PreviewCompatibleImage from "../components/utilities/PreviewCompatibleImage";

import BrandButton from "../components/BrandButton";
import Sharing from "../components/Sharing.js";

import HeroSection from "../components/HeroSection.js";

const MediaDownloadSharePageTemplate = ({ data }) => {
  const [isCompleted, setIsCompleted] = useState(false);

  return (
    <>
      <Layout
        // header xmas image too small in Header Component
        imageObject={data.headerObject.imageObject}
        seoObject={data.seoObject}
      >
        <HeroSection className="">
          <div className="space-y-5 sm:space-y-10">
            <div className="text-center">
              <RenderMarkdown
                markdownContent={data.heroObject.title}
                isMarkdown={false}
                className="font-medium text-4xl sm:text-6xl text-brand-gold leading-tight mt-0"
                whitespace="normal"
              />
              <RenderMarkdown
                markdownContent={data.heroObject.subtitle}
                isMarkdown={true}
                className="text-2xl sm:text-4xl text-brand-green mt-5 sm:mt-14"
                whitespace="pre-line"
              />
            </div>
            <div className="text-center">
              <ConditionalWrap
                condition={data.heroObject.imageObject.linkUrl}
                wrap={(children) => (
                  <TrackedLink href={data.heroObject.imageObject.linkUrl}>
                    {children}
                  </TrackedLink>
                )}
              >
                <PreviewCompatibleImage
                  imageObject={data.heroObject.imageObject}
                  className=" h-full max-w-screen-lg my-5"
                />
              </ConditionalWrap>
            </div>
            <div className="flex justify-center">
              <BrandButton className="max-w-xs" href="/adventskalender.pdf">
                <RenderMarkdown
                  markdownContent="## Download"
                  whitespace="normal"
                />
              </BrandButton>
            </div>
            <Sharing
              sharingObject={data.heroObject.sharingObject}
              onClickEffect={() => setIsCompleted(true)}
            />
          </div>
        </HeroSection>
        <Section>
          <RenderMarkdown
            markdownContent={data.subtitle}
            className="text-center"
          />
        </Section>
      </Layout>
    </>
  );
};

const MediaDownloadSharePage = ({ data }) => {
  return (
    <MediaDownloadSharePageTemplate data={data.markdownRemark.frontmatter} />
  );
};

export default MediaDownloadSharePage;

export const pageQuery = graphql`
  query MediaDownloadSharePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
            alt
            linkUrl
          }
          title
          subtitle
          sharingObject {
            title
            linkUrl
            facebook {
              sharingText
              linkText
            }
            whatsApp {
              sharingText
              linkText
            }
          }
          ctaObject {
            linkText
            linkUrl
          }
        }
        subtitle
      }
    }
  }
`;
